import "components/Hamburger";
import { Header as BaseHeader } from "@olmokit/components/Header";
// import "utils/logo.scss";
import "./index.scss";

/**
* Component: Header
*
*/
export function Header() {
  const header = BaseHeader();
  
  document.addEventListener('click', (e)=> {
    if (e.target.classList.contains('gtm-logo')) {
      dataLayer.push({
        'event': 'click',
        'category': 'menu',
        'action': 'header',
        'label': 'logo'
      });
    }
    if (e.target.classList.contains('gtm-header')) {
      dataLayer.push({
        'event': 'click',
        'category': 'menu',
        'action': 'header',
        'label': e.target.getAttribute('data-anchor'),
      });
    }
  })
  
  return {
    /**
    * Set variant
    *
    * @param {"light" | "dark"} variant
    */
    setVariant(variant) {
      const { className } = header.$root;
      header.$root.className = className.replace(
        /(is-).+(\s*)/,
        `$1${variant}$2`
        );
      },
    };
  }
