/**
* @file Route: home
*
* This file is compiled as a standalone entrypoint and it is included by
* default in its blade template.
* This is a "standard" route, it does not have a structure to follow, you are
* sure the DOM is always ready and the JS will only be executed in this route.
*/

import "layouts/main";
import { breakpoints, gridGutter } from "@olmokit/core/scss";
import { $, $all, on, forEach, addClass, removeClass } from "@olmokit/dom";
import "@olmokit/core/glide/bullets";
import "@olmokit/core/glide/arrows";
import { replaceFragment } from "@olmokit/core/fragments";
import { FormContact } from "components/FormContact";
import "./index.scss";

console.log("Route home/index.js mounted.");

document.addEventListener('click', (e)=> {
    if (e.target.classList.contains('gtm-cta')) {
        dataLayer.push({
            'event': 'click',
            'category': 'cta',
            'action': e.target.getAttribute('data-cta'),
            'label': e.target.getAttribute('data-anchor'),
        });
    }
})

function sliderTeachers() {
    import("@olmokit/core/glide").then(({glide, Anchors, AutoHeight, Autoplay, Breakpoints, Controls, Swipe}) => {
        const sliderContainer = $(".Teachers:slider");
        const glideOptions = {
            loop: true,
            autoplay: 6000,
            hoverpause: true,
            animationDuration: 600,
            killWhen: { above: 99999 },
            bound: true,
            autoHeight: false, // if true, remove {align-items: center;} from .glide__slides
            breakpoints: {
                [breakpoints.over]: {
                    perView: 3,
                    gap: gridGutter,
                    peek: { before: 0, after: 0 },
                },
                [breakpoints.md]: {
                    perView: 2,
                    gap: gridGutter,
                    peek: { before: 0, after: 0 },
                },
                [480]: {
                    perView: 1,
                    gap: gridGutter,
                    peek: { before: 0, after: 0 },
                },
            },
        };
        const slider = glide(sliderContainer, glideOptions);
        slider.mount({
            Anchors,
            AutoHeight,
            Autoplay,
            Breakpoints,
            Controls,
            Swipe
        });
    }); 
}

sliderTeachers();
replaceFragment("contactForm", "components.FormContact").then(() => FormContact());
