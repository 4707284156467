import "./index.scss";

/**
 * Component: Footer
 */
export function Footer() {
  console.log("Footer mounted.");

  document.addEventListener('click', (e)=> {
    if (e.target.classList.contains('gtm-footer')) {
      dataLayer.push({
        'event': 'click',
        'category': 'menu',
        'action': 'footer',
        'label': e.target.getAttribute('data-anchor'),
      });
    }
  })

}

