import "@olmokit/core/reboot/index.scss";
import "@olmokit/core/typography/reset.scss";
import "@olmokit/core/debug/api";
import "@olmokit/core/grid/index.scss";
import "@olmokit/core/icon/index.scss";
import "@olmokit/core/img";
// import LazyLoad from "@olmokit/core/lazy";
import "components/Image";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import "components/Cta";
import "utils/btn.scss";
import "utils/fonts.scss";
import "utils/logo.scss";
import "utils/acanto.scss";

import "./index.scss";

Header();
Footer();

// new LazyLoad();
