import Olmoforms from "@olmokit/core/olmoforms/feedback";
import "@olmokit/core/forms/input/filled";
import "@olmokit/core/forms/checkbox";
import "@olmokit/core/forms/textarea/filled";
import "./index.scss";

/**
 * Component: FormContact
 *
 */
export function FormContact() {

  const olmoform = Olmoforms(".FormContact:", {
    sent: (formData) => {
      dataLayer.push({
        ...formData,
        event: "form sent",
        form: "Form Contatti",
      });
    },
  });
  
  return {
    destroy() {
      olmoform.destroy();
    },
  };

}
